<template>
  <div
    class="sort-label"
    :class="{ 'text-primary': sort.field === fieldName}"
  >
    <span class="mr-2"><slot /></span>
    <div class="sort-label__icon">
      <i :class="['fas', sortedClass]" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    sort: Object,
    fieldName: String
  },
  computed: {
    sortedClass() {
      if (this.sort.field === this.fieldName) {
        if (this.sort.direction === 'asc') {
          return 'fa-sort-amount-down-alt'
        }
        if (this.sort.direction === 'desc') {
          return 'fa-sort-amount-up'
        }
      }

      return 'fa-sort';
    },
  }
}
</script>

<style lang="scss" scoped>
.sort-label {
  display: flex;
  cursor: pointer;
  align-items: center;

  &__icon {
    width: 13px;
    height: 13px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  i {
    font-size: 13px;
  }
}
</style>
