<template>
  <div
    class="dismissal-status"
    :class="`dismissal-status_${color}`"
  >
    {{ status }}
  </div>
</template>

<script>
const colors = {
  pending: 'yellow',
  dismissed: 'red',
  resolved: 'green'
}

export default {
  props: {
    status: String
  },
  computed: {
    color() {
      return colors[this.status];
    }
  }
}
</script>

<style lang="scss" scoped>
.dismissal-status {
  color: #fff;
  padding: 0 4px;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 15px;
  border-radius: 4px;
  display: inline-block;

  &_yellow {
    background: #FFC107;
  }
  &_red {
    background: #DC3545;
  }
  &_green {
    background: #28A745;
  }
}
</style>