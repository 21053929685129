<template>
  <b-dropdown
    :right="right"
    ref="dropdown"
    variant="outline-secondary"
    class="dropdown-select"
  >
    <template #button-content>
      <div class="dropdown-select__content">
        <div>{{ valueLabel }}</div>
        <i class="bx bxs-down-arrow" />
      </div>
    </template>
    <div
      class="option"
      @click="selectAllOptions"
    >
      <div class="option__name">{{ allOptionsText }}</div>
      <i v-if="isOptionSelected(allOptionValue)" class="bx bx-check category__icon" />
    </div>
    <div
      v-for="option in options"
      :key="getValue(option)"
      class="option"
      @click="onSelect(option)"
    >
      <div class="option__name">{{ getLabel(option) }}</div>
      <i v-if="isOptionSelected(option)" class="bx bx-check option__icon" />
    </div>
  </b-dropdown>
</template>

<script>
export default {
  name: 'LibraryCategoryFilter',
  props: {
    label: {
      type: String,
      default: ''
    },
    valueKey: {
      type: String,
      default: ''
    },
    options: {
      type: Array,
      default: () => []
    },
    value: [Object, String, Array],
    allOptionsText: {
      type: String,
      default: ''
    },
    right: {
      type: Boolean,
      default: false
    },
    multiple: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    onSelect(option) {
      this.$refs.dropdown.hide(true);
      if (this.multiple) {
        if (this.isOptionSelected(option)) {
          const index = this.modelValue.findIndex((el) => el.id === option?.id);
          if (index + 1) {
            this.modelValue.splice(index, 1);
          }
        } else {
          this.modelValue.push(option);
        }
        if (this.modelValue.length === 0) {
          this.modelValue.push(this.allOptionValue);
        } else if (this.isOptionSelected(this.allOptionValue) && this.modelValue.length > 1) {
          this.modelValue.splice(0, 1);
        } else if (this.modelValue.length === this.options.length) {
          this.modelValue = [this.allOptionValue];
        }
      } else {
        this.modelValue = this.isOptionSelected(option) ? this.allOptionValue : option;
      }
    },
    selectAllOptions() {
      this.$refs.dropdown.hide(true);
      this.modelValue = this.multiple ? [this.allOptionValue] : this.allOptionValue;
    },
    getLabel(option) {
      const label = this.label ? option[this.label] : option;
      return label === null ? this.allOptionsText : label;
    },
    getValue(option) {
      return this.valueKey ? option[this.valueKey] : option;
    },
    isOptionSelected(option) {
      const value = this.getValue(option);
      if (this.multiple) {
        return this.modelValue.map((item) => this.getValue(item)).includes(value);
      }

      return this.getValue(this.modelValue) === value;
    }
  },
  computed: {
    modelValue: {
      set(value) {
        this.$emit('input', value);
      },
      get() {
        return this.value;
      }
    },
    allOptionValue() {
      return this.valueKey
        ? { [this.valueKey]: null, [this.label]: this.allOptionsText }
        : null
    },
    valueLabel() {
      if (this.multiple) {
        return this.modelValue.length === 1 ? this.getLabel(this.modelValue[0]) : `${this.modelValue.length} items selected`;
      }

      return this.getLabel(this.modelValue);
    }
  },

}
</script>

<style lang="scss" scoped>
.dropdown-select {
  width: 100%;
  :deep(.dropdown-toggle) {
    padding: 0;
    border: none;
    max-width: 100%;
    background-color: transparent;

    &:active {
      border: none;
      background-color: transparent;
    }
  }

  &.show &__content i {
    transform: rotate(180deg);
  }

  &__content {
    gap: 10px;
    display: flex;
    align-items: center;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    max-width: 100%;
    color: #2A3042;

    div {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      max-width: calc(100% - 20px);
    }

    i {
      font-size: 10px;
      margin-top: 3px;
      color: #686D81;
    }
  }
}
:deep(.dropdown-menu) {
  min-width: 200px;
  max-height: 300px;
  overflow-y: auto;


  &.show {
    top: calc(100% + 10px ) !important;
  }
}
:deep(.dropdown-menu) {
  padding: 10px 20px;
}
.option {
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: space-between;

  &__name {
    color: #2A3042;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }

  &__icon {
    font-size: 20px;
    color: #556EE6;
  }
}
.option + .option {
  margin-top: 10px;
}
</style>
