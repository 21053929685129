<template>
  <b-modal
    v-model="isOpen"
    size="lg"
    centered
    dialog-class="templates-modal"
  >
    <template #modal-title>
      <div class="w-100 d-flex align-items-center">
        <div class="w-50 templates-modal__title">Templates</div>
        <div class="w-50">
          <div class="templates-modal__actions">
            <i
              class="bx bx-plus"
              @click="handleSelectTemplate(null)"
            />
            <i
              class="bx bxs-trash-alt"
              @click="handleDeleteTemplate"
            />
          </div>
        </div>
      </div>
    </template>
    <div class="row" v-load="loading">
      <div class="col-4 border-right py-4 px-0">
        <ul class="templates-modal__list templates-list">
          <li
            v-for="template in templates"
            :key="template.id"
            class="templates-list__item"
            :class="{'templates-list__item_selected': selectedTemplate?.id === template.id}"
            @click="handleSelectTemplate(template)"
          >
            <span>{{ template.title }}</span>
          </li>
        </ul>
      </div>
      <div class="col-8 p-4">
        <b-form-group
          label="Category (Template Title)"
          label-for="template-title"
        >
          <b-form-input
            id="template-title"
            v-model="form.title"
            :class="{ 'is-invalid': $v.form.title.$error }"
          />
        </b-form-group>
        <b-form-group
          label="Template message"
          label-for="template-message"
        >
          <b-form-textarea
            id="template-message"
            v-model="form.message"
            rows="5"
            max-rows="6"
            :class="{ 'is-invalid': $v.form.message.$error }"
          />
        </b-form-group>
      </div>
    </div>
    <template #modal-footer>
      <b-button
        variant="primary"
        class="templates-modal__button"
        :disabled="isSaveButtonDisabled"
        @click="handleSubmit"
      >
        Save
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import { mapActions } from 'vuex';

export default {
  props: {
    value: Boolean,
  },
  data() {
    return {
      form: {
        title: '',
        message: ''
      },
      loading: false,
      selectedTemplate: null
    }
  },
  validations: {
    form: {
      title: { required },
      message: { required },
    }
  },
  mounted() {
    this.getDismissalTemplates();
  },
  methods: {
    ...mapActions('dismissalWarning', [
      'getDismissalTemplates',
      'createDismissalTemplate',
      'updateDismissalTemplate',
      'deleteDismissalTemplate'
    ]),
    async handleSubmit() {
      this.$v.form.$touch();
      if (!this.$v.form.$anyError) {
        const data = {
          title: this.form.title,
          template_message: this.form.message
        }
        this.loading = true;
        if (this.selectedTemplate) {
          this.selectedTemplate = await this.updateDismissalTemplate({ id: this.selectedTemplate.id, data });
        } else {
          this.selectedTemplate = await this.createDismissalTemplate(data);
        }
        await this.getDismissalTemplates();
        this.$v.$reset();
        this.loading = false;
      }
    },
    handleSelectTemplate(template) {
      this.selectedTemplate = template;
    },
    handleDeleteTemplate() {
      if (this.selectedTemplate) {
        const { id, title } = this.selectedTemplate;
        this.$bvModal.msgBoxConfirm(`Delete ${title} template?`, {
          title: 'Please Confirm',
          okVariant: 'danger',
          okTitle: 'Yes',
          cancelTitle: 'No',
          footerClass: 'p-2',
          hideHeaderClose: false,
          centered: true
        })
          .then(async (value) => {
            if (value) {
              this.loading = true;
              await this.deleteDismissalTemplate(id);
              await this.getDismissalTemplates();
              this.loading = false;
              this.selectedTemplate = null;
            }
          });
      }
    }
  },
  computed: {
    isOpen: {
      set(value) {
        this.$emit('input', value);
      },
      get() {
        return this.value;
      }
    },
    templates() {
      return this.$store.getters['dismissalWarning/activeTemplates'];
    },
    isSaveButtonDisabled() {
      if (this.selectedTemplate) {
        return this.form.title === this.selectedTemplate.title &&
          this.form.message === this.selectedTemplate.template_message;
      } else {
        return !(this.form.title && this.form.message);
      }
    }
  },
  watch: {
    selectedTemplate(template) {
      this.form.title = template ? template.title : '';
      this.form.message = template ? template.template_message : '';
    },
    isOpen(value) {
      if (value) {
        this.selectedTemplate = this.templates[0] ?? null;
      }
    }
  }
}
</script>

<style lang="scss" scoped>
:deep(.templates-modal) {
  .modal-title {
    width: 100%;
  }
  .modal-header {
    padding: 11px 20px;
    align-items: center;

    .close {
      margin: 0;
      padding: 0;
    }
  }
  .modal-body {
    padding: 0px 12px;
  }
}
.templates-modal {
  &__title {
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
  }
  &__actions {
    width: 92px;
    height: 30px;
    display: flex;
    padding: 0 20px;
    align-items: center;
    border-radius: 60px;
    background: #EFF2F7;
    justify-content: space-between;

    i {
      cursor: pointer;
    }
  }
  &__button {
    width: 100px;
  }
  &__list {
    margin: 0;
    padding: 0;
    max-height: 300px;
    overflow-y: scroll;
  }
}
.templates-list {
  &__item {
    height: 31px;
    display: flex;
    cursor: pointer;
    list-style: none;
    padding: 0px 20px;
    align-items: center;

    &_selected {
      background: #EFF2F7;
    }
    
    span {
      display: block;
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: pre;
    }
  }
}
</style>
