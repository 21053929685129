<template>
  <b-modal
    v-model="isOpen"
    title="New Dismissal Warning"
    size="md"
    centered
    dialog-class="templates-modal"
    @hide="handleHideModal"
  >
    <b-form-group
      class="required"
      label="Template"
      label-for="warning-template"
    >
      <multiselect
        id="warning-template"
        v-model="form.template"
        :options="templates"
        track-by="id"
        :show-labels="false"
        label="title"
        class="app-multiselect"
        :class="{ 'is-invalid': $v.form.template.$error }"
        placeholder="Choose Template"
      />
    </b-form-group>
    <b-form-group
      label="Employee"
      label-for="warning-employee"
      class="required"
    >
      <multiselect
        id="warning-employee"
        v-model="form.employee"
        :options="employees"
        track-by="uid"
        :show-labels="false"
        label="name"
        class="app-multiselect"
        :class="{ 'is-invalid': $v.form.employee.$error }"
        placeholder="Choose employee"
      />
    </b-form-group>
    <b-form-group
      label="Message"
      label-for="warning-message"
      class="required"
    >
      <b-form-textarea
        id="warning-message"
        v-model="form.message"
        rows="5"
        max-rows="6"
        :class="{ 'is-invalid': $v.form.message.$error }"
      />
    </b-form-group>
    <template #modal-footer>
      <b-button
        variant="danger"
        class="templates-modal__button"
        @click="handleSubmit"
      >
        Send Warning
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import { required } from 'vuelidate/lib/validators';

export default {
  props: {
    value: Boolean,
  },
  data() {
    return {
      form: {
        template: null,
        message: '',
        employee: null
      }
    }
  },
  validations: {
    form: {
      template: { required },
      message: { required },
      employee: { required }
    }
  },
  methods: {
    async handleSubmit() {
      this.$v.form.$touch();
      if (!this.$v.form.$anyError) {
        this.$emit('onSubmit', { ...this.form });
        this.isOpen = false;
      }
    },
    handleHideModal() {
      this.form.message = '';
      this.form.template = null;
      this.form.employee = null;
      this.$v.$reset();
    }
  },
  computed: {
    templates() {
      return this.$store.getters['dismissalWarning/activeTemplates'];
    },
    employees() {
      return this.$store.state.employees.subordinates;
    },
    isOpen: {
      set(value) {
        this.$emit('input', value);
      },
      get() {
        return this.value;
      }
    },
  },
  watch: {
    'form.template'(value) {
      this.form.message = value?.template_message ?? '';
    }
  }
}
</script>
